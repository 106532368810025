import React from "react"
import Layout from "../components/layout"


export default () => (
  <Layout>
    <h2>Estetik, konfor, pratiklik ve kalite üretimde üzerinde durduğumuz en temel esaslardır.</h2>
    <p>Boran inşaat ürünleri en yüksek standartlarda dayanıklılık ve rahatlık göz önünde bulundurularak siz seçkin müşterilerimizin kullanımına sunulmaktadır. Boran inşaat ürünleri her eve uyum sağlayacak şekilde imal edilmekte, geleneksel, modern ve alışılmamış modelleri ile kendi tarzını belirlemektedir. Ürünlerimiz değişen tasarımları ile her türlü ihtiyaca cevap verebilecek kapasitededir. Estetik, konfor, pratiklik ve kalite üretimde üzerinde durduğumuz en temel esaslardır.</p>
    <div>
      <img
        src="http://www.borankay.com/wp-content/uploads/2010/08/room1.jpg"
        alt="Group of pandas eating bamboo"
      />
  
    </div>
  </Layout>
)